import { Layout } from 'src/@legacy/@core/components/share/ANTD/Layout'
import { AppLink } from 'src/@legacy/@core/components/share/AppLink'
import { useTranslation } from 'src/@legacy/@core/hooks/UserTranslation'
import Image from 'next/image'
import React, { FC, useEffect, useState } from 'react'
import {
  RESOURCE_IMAGE_FREEC_LOGO_EN,
  RESOURCE_IMAGE_FREEC_LOGO_VN,
  RESOURCE_IMAGE_ICON_USER,
  RESOURCE_IMAGE_HEADER_ARROW_RIGHT
} from '../utilities/Constant'
import {
  LINK_URL_LOGIN,
  LINK_URL_EMPLOYER_CONTACT,
  LINK_URL_CANDIDATE,
  LINK_URL_REGISTER,
  LINK_URL_EMPLOYER_HR_GLOSSARY,
  LINK_URL_EMPLOYER_JD_EXAMPLES
} from '../utilities/LinkURL'
import ChangeLanguageDropdown from 'src/@legacy/@core/components/ChangeLanguageDropdown'
import SideBarBeforeLogin from './SideBarBeforeLogin'
import { useCurrentCompany } from 'src/@legacy/hooks/CurrentCompanyHooks'
import { getCookie } from '../utilities/Cookies'
import { Dropdown, Menu } from '../@core/components/share/ANTD'

import { DownOutlined } from '@ant-design/icons'
import { useRouter } from 'next/router'

type HeaderProps = {}
const HeaderBeforeLogin: FC<HeaderProps> = () => {
  const router = useRouter()
  const [languageCookies, setLanguageCookies] = useState(undefined)
  const { translate, currentLanguage } = useTranslation()
  const [currentCompany] = useCurrentCompany()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setLanguageCookies(getCookie('X_LANGUAGE'))
    }
  }, [])

  return (
    <>
      <Layout.Header className="header header-hide-mobile none-shadow">
        <div className="left-group">
          <AppLink keepUTM href="/">
            <a className="header-logo-img">
              <div className={`logo-freec ${languageCookies === 'vi' ? 'logo-vn' : ''}`}>
                {currentLanguage === 'en' ? (
                  <Image quality={100} src={RESOURCE_IMAGE_FREEC_LOGO_EN} alt="freec-platform" width={120} height={34} />
                ) : (
                  <Image quality={100} src={RESOURCE_IMAGE_FREEC_LOGO_VN} alt="freec-platform" width={150} height={34} />
                )}
              </div>
            </a>
          </AppLink>
          <div className="header-link-group">
            <AppLink
              keepUTM
              href={`${LINK_URL_EMPLOYER_CONTACT}?utm_campaign=HEADHUNTER_LOCAL&utm_source=Employer-LP&utm_medium=ContactUsHeader`}>
              <a className={`header-link`}>{translate('footer:contact_us')}</a>
            </AppLink>

            <Dropdown
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              overlay={
                <Menu>
                  <Menu.Item onClick={() => {}}>
                    <AppLink keepUTM href={LINK_URL_EMPLOYER_HR_GLOSSARY}>
                      <a>{translate('footer:hr_glossary')}</a>
                    </AppLink>
                  </Menu.Item>
                  <Menu.Item onClick={() => {}}>
                    <AppLink keepUTM href={LINK_URL_EMPLOYER_JD_EXAMPLES}>
                      <a>{translate('footer:jd_examples')}</a>
                    </AppLink>
                  </Menu.Item>
                </Menu>
              }>
              <a
                className={`header-link header-link__dropdown ${router.pathname.includes('hr-glossary') ? 'active' : ''}`}
                onClick={(e) => e.preventDefault()}>
                Resources
                <DownOutlined className="header-link__dropdown-icon" />
              </a>
            </Dropdown>
          </div>
        </div>

        <div className="right-group">
          {!currentCompany && (
            <div className="header-sign-in-up">
              <AppLink keepUTM prefetch={false} href={LINK_URL_LOGIN} as={LINK_URL_LOGIN}>
                <a className="btn-login"> {translate('header:login')}</a>
              </AppLink>
              {/* <span>/</span> */}
              <AppLink keepUTM prefetch={false} href={LINK_URL_REGISTER} as={LINK_URL_REGISTER}>
                <a className="btn-register">{translate('header:register')}</a>
              </AppLink>
            </div>
          )}
          <ChangeLanguageDropdown />

          <div className="header-employer">
            <AppLink prefetch={false} href={LINK_URL_CANDIDATE} as={LINK_URL_CANDIDATE}>
              <a className="btn d-flex flex-wrap align-items-center">
                <div className="icon-left d-flex align-items-center justify-content-center">
                  <Image src={RESOURCE_IMAGE_ICON_USER} alt="freec-platform" width={40} height={40} />
                </div>
                <div className="btn-info">
                  <div className="title">{translate('header:for_job_seeker')}</div>
                  <div className="sub-title">{translate('header:btn_job_seeker_sub')}</div>
                </div>
                <div className="icon-right">
                  <Image src={RESOURCE_IMAGE_HEADER_ARROW_RIGHT} alt="freec-platform" width={24} height={24} />
                </div>
              </a>
            </AppLink>
          </div>
        </div>
      </Layout.Header>
      <SideBarBeforeLogin />
    </>
  )
}
export default HeaderBeforeLogin
